import React from "react";
import styles from "./Button.module.css";

const Button = ({ label, link }) => {
  // Ensure the link starts with 'http://' or 'https://'
  const isExternalLink = link.startsWith("http://") || link.startsWith("https://");

  return (
    <a href={isExternalLink ? link : `http://${link}`} target="_blank" rel="noopener noreferrer">
      <button className={`${styles.button}`}>{label}</button>
    </a>
  );
};

export default Button;
