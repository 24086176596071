import React from "react";
import styles from "./Footer.module.css";
import RandomTextReveal from "../TextReveal/TextReveal";

const Footer = () => {
  const FooterLinks = [
    { linkName: "ABOUT", linkUrl: "#ABOUT" },
    { linkName: "BLUEPRINT", linkUrl: "#BLUEPRINT" },
    { linkName: "TOKENOMICS", linkUrl: "#TOKENOMICS" },
    { linkName: "FRAMEWORK", linkUrl: "#FRAMEWORK" },
    { linkName: "FAQ's", linkUrl: "#FAQ's" },
  ];

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="flex justify-center py-4">
      <div className="w-[95%] md:w-[85%] p-[1rem] border border-[#6142ff] text-white">
        <div className="flex flex-col pb-2 lg:flex-row">
          <div className="W-[100%] lg:w-[50%] p-[0.25rem] pb-20 lg:p-[1rem] gap-6 flex flex-col">
            <div className="flex gap-4">
              <div>
                <img
                  src="/assets/images/logo.png"
                  alt="Logo"
                  width="38px"
                  height="38px"
                />
              </div>
              <div className="flex items-center">
                <h2 className={`${styles.footerHeadingName} text-[#e4e4e4]`}>
                  MOONVAULT
                </h2>
              </div>
            </div>

            <div className="lg:w-[70%]">
              <h4 className={`${styles.footerContentText}`}>
                Transform Your Crypto Experience
              </h4>
            </div>

            <div className="lg:w-[90%]">
              <p className={`text-dull-color ${styles.footerContentSubText}`}>
                Embark on a revolutionary crypto journey with MoonVault. Our unique framework, powered by dynamic scenarios and our custom Telegram Bot, unveils groundbreaking tokenomics designed for lasting impact. Engage with an innovative DeFi ecosystem that prioritizes user value and sustainable growth.
              </p>
            </div>
          </div>
          <div className="w-[100%] lg:w-[50%] p-[0.25rem] lg:p-[1rem]">
            {FooterLinks.map((items, index) => (
              <div
                key={index}
                className={`py-4 ${index === FooterLinks.length - 1
                  ? "border border-b-[#6142ff] border-l-0 border-r-0 border-t-[#6142ff]"
                  : "border border-b-0 border-l-0 border-r-0 border-t-[#6142ff]"
                  }`}
              >
                <a
                  href={items.linkUrl}
                  className={`${styles.footerLinks} font-medium cursor-pointer`}
                  onClick={(e) => scrollToSection(e, items.linkUrl)}
                >
                  <RandomTextReveal textToReveal={items.linkName} />
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center">
          <div
            className={`text-dull-color flex justify-between w-[100%] px-[0.25rem] italic ${styles.footerCopyrightText}`}
          >
            <div>
              <p>&copy; All Rights Reserved</p>
            </div>
            <div>
              <a href="/">
                <p>Privacy Policy</p>
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className={`text-dull-color italic ${styles.footerCopyrightText}`}>Disclaimer: MoonVault is an experimental DeFi project and not intended as financial advice, an investment, or any form of security. MoonVault and $MVT have no intrinsic value. Always conduct your own research before participating.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
