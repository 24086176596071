import React from "react";
import styles from "./Blueprint.module.css";
import QuestionsMoon from "../questionsMoon/QuestionsMoon";

const data = [
  {
    number: "01",
    question: "Dynamic Smart Contract Framework with Telegram Bot Integration",
    answer:
      "MoonVault’s unique tokenomics framework activates Modes and Events in random sequences, all shown by our custom Telegram Bot, ensuring a dynamic and engaging ecosystem.",
  },
  {
    number: "02",
    question: "Practical Utility in DeFi",
    answer:
      "MoonVault aims to create practical utility in the DeFi space, providing real-world financial solutions beyond speculative trading. By focusing on sustainable growth and practical applications, MoonVault seeks to enhance its long-term value and impact within the decentralized finance ecosystem.",
  },
  {
    number: "03",
    question: "Decentralization vs. Centralization Balance",
    answer:
      "MoonVault acknowledges the necessity of certain centralized structures working in tandem with a decentralized infrastructure to foster growth and prosperity within the community.",
  },
];

const Blueprint = () => {
  return (
    <section id="BLUEPRINT" className="flex justify-center py-20 text-white">
      <div className="flex-col lg:flex lg:flex-row w-[95%] md:w-[85%]">
        <div className="lg:w-[25%] pb-5 lg:pb-0">
          <h4 className={styles.tagline}>/ BLUEPRINT</h4>
        </div>
        <div className="lg:w-[75%] p-[0.25rem] lg:p-[1rem]">
          <QuestionsMoon data={data} />
        </div>
      </div>
    </section>
  );
};

export default Blueprint;
