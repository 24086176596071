import React from "react";
import About from "./../components/about/About";
import Blueprint from "./../components/blueprint/Blueprint";
import Faq from "./../components/faq/Faq";
import Hero from "./../components/hero/Hero";
import SocialCommunity from "./../components/socialCommunity/SocialCommunity";
import Tokenomics from "./../components/tokenomics/Tokenomics";
import Framework from "./../components/framework/Framework";

const Home = () => {
  return (
    <div className="w-[100%] max-w-[1600px] mx-auto">
      <Hero />
      <About />
      <Blueprint />
      <Tokenomics />
      <Framework />
      <Faq />
      <SocialCommunity />
    </div>
  );
};

export default Home;
