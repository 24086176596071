import React from "react";
import styles from "./Faq.module.css";
import QuestionsMoon from "../questionsMoon/QuestionsMoon";

const data = [
  {
    number: "01",
    question: "Do I have to wait for my dividends to be distributed?",
    answer:
      "Rewards are distributed automatically through an iterative process. However, if you prefer not to wait, you may manually claim your rewards every 15 minutes.",
  },
  {
    number: "02",
    question: "Is there a tax on transfers?",
    answer:
      "Yes, transfers are taxed the same as buys and sells to ensure that rewards are accounted for accurately.",
  },
  {
    number: "03",
    question: "Do I have to claim LP rewards?",
    answer:
      "No, rewards for liquidity providers are automatic through the Liquidity Injection process. There is no need to manually claim them.",
  },
];

const Faq = () => {
  return (
    <section id="FAQs" className="flex justify-center py-20 text-white">
      <div className="flex-col lg:flex lg:flex-row w-[95%] md:w-[85%]">
        <div className="lg:w-[25%] pb-5 lg:pb-0">
          <h4 className={styles.tagline}>/ FAQ'S</h4>
        </div>
        <div className="lg:w-[75%] p-[0.25rem] lg:p-[1rem]">
          <QuestionsMoon data={data} />
        </div>
      </div>
    </section>
  );
};

export default Faq;
