import React from "react";
import styles from "./About.module.css";
import Button from "../button/Button";
const data = [
  {
    subHeading: "Self-Triggered Replenishment",
    heading: "Auto LP Injections",
    content:
      " Initial liquidity for $MVT is burned. The self-triggered replenishment function triggers decentralized liquidity injections, enhancing market stability and reducing price impact for future trades. These injections grow LP depth without causing sells on the chart, ensuring a stable and resilient liquidity pool.",
    video: "/assets/videos/lp.mp4",
  },
  {
    subHeading: "Self-Triggered Redistribution",
    heading: "Auto Dividends",
    content:
      " The self-triggered redistribution functions rotate between automatic $ETH rewards as $WETH and auto buyback and reflect as $MVT. This mechanism promotes long-term engagement and value growth by distributing both $ETH and $MVT to holders, while supporting $MVT's market price through buybacks.",
    video: "/assets/videos/reflect.mp4",
  },
  {
    subHeading: "Self-Triggered Refuel",
    heading: "Auto Burn",
    content:
      " The self-triggered rebalancing mechanism reduces the total supply of $MVT tokens through automatic burns, ensuring a stable and appreciating token value while balancing the growing reserves accumulated through LP injections.",
    video: "/assets/videos/burn.mp4",
  },
];

const About = () => {
  return (
    // About Parent Class
    <section id="ABOUT" className="flex justify-center w-full py-10 AboutParent">
      {/* About Child Class Starts */}
      <div className="AboutChild w-[95%] sm:w-[85%] text-white">
        {/* Content Class */}
        <div className="context mb-[5rem]">
          {/* TagLine */}
          <h4 className={styles.tagline}>/ ABOUT MOONVAULT</h4>
          {/* Heading */}
          <h3 className={`w-full lg:w-[55%] ${styles.heading}`}>
            GUIDED BY THE REVOLVING S.T.A.R.R. <i className="font-bold">$MVT</i>  TOKENOMICS
          </h3>
          {/* Sub-Content */}
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            MoonVault is a DeFi Initiative on the Ethereum Network, Powered by the Revolving S.T.A.R.R. Engine.
          </p>
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            <span className="italic font-bold">S.T.A.R.R.</span> (Self-Triggered Automatic Regeneration and Redistribution) Is an Advanced Tokenomics Framework Designed to Enhance User Engagement and Value Creation in Decentralized Finance.
          </p>
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            Programmatically Randomized and Automatic Tokenomic Functionality Featuring Buyback-and-Reflect in $MVT, Direct Rewards in $ETH, Supply Reductions, Liquidity Injections, and LP Rewards.
            All Gas-Optimized for Ethereum Using S.T.A.R.R.
          </p>
          <div className="flex gap-10 py-5 pl-2">
            <div><Button label={"GET STARTED"} link={"#"} /></div>
            <div><Button label={"GET STARTED"} link={"#"} /></div>
          </div>
        </div>

        <div className="aboutCards flex flex-wrap justify-center xl:justify-between gap-[1rem] pt-5">
          {data.map((items, index) => {
            return (
              <div key={index}
                className={`w-[100%] sm:w-[67%] md:w-[55%] xl:w-[32%] flex justify-between flex-col h-auto ${styles.aboutBox}`}
              >
                <div className="aboutBoxContent">
                  <h3 className={styles.Replenishment}>{items.subHeading}</h3>
                  <h1 className={styles.aboutBoxHeading}>{items.heading}</h1>
                  <p className={styles.aboutBoxContent}>{items.content}</p>
                </div>

                <div className="video">
                  <div>
                    <video playsInline={true}
                      muted={true}
                      autoPlay={true}
                      loop={true}
                      controls={false}
                      preload="auto"
                      width="100%"
                      height="100%" className="object-cover w-full h-full overflow-hidden pointer-events-none select-none">
                      <source src={items.video} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default About;
