import React, { useState, useEffect } from "react";
import styles from "./Hero.module.css";

const Hero = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const earthStyle = {
    bottom: `${scrollY * 0.025}px`,
    transition: "bottom 0.25s ease-in-out",
    backgroundImage: `url("/assets/images/moonbase-earth.png")`,
  };

  return (
    <section className={`w-[100%] h-[100vh] relative pb-4 ${styles.heroSection}`}>
      <div
        className={`absolute -z-2 w-[100%] h-[100vh] bg-cover ${styles.heroGif}`}
        style={{ backgroundImage: `url("/assets/videos/gifComputers.gif")` }}
      ></div>
      <div
        id="Earth"
        style={earthStyle}
        className={`absolute -z-50 w-[100%] h-[100vh] bg-cover ${styles.heroEarth}`}
      ></div>
      <div
        className={`-z-20 w-[100%] h-[100vh] bg-cover absolute ${styles.heroBg}`}
        style={{ backgroundImage:`url("/assets/images/heroSection.png")` }}
      ></div>
    </section>
  );
};

export default Hero;
