import React from "react";
import Marquee from "react-fast-marquee";
import styles from "./marqueeComponent.module.css";

const MarqueeComponent = () => {
  const marqueeBox = [
    {
      colouredText: "Socials:",
      text: "Stay tuned to our social media channels for the most recent updates.",
    },
    {
      colouredText: "Our token is now live:",
      text: "You can buy moonvault on your favorite dex's",
    },
    {
      colouredText: "Contract Address:",
      text: `Our contract address is "0x72e4f9f808c49a2a61de9c5896298920dc4eeea9"`,
    },
  ];

  return (
    <section className="h-[45px] border border-[#6142ff] bg-black text-white w-[100%] flex items-center">
      <div className="w-[30%] md:w-[20%] lg:w-[10%] flex justify-center">
        <p className={`${styles.tickerText} text-dull-color italic`}>Now live<span className={styles.blinker}>{"  "}•</span></p>
      </div>
      <div className="border h-[100%] border-[#6142ff]"></div>
      <div className="w-[70%] md:w-[80%] lg:w-[90%]">
        <Marquee speed={"30"} pauseOnHover>
          {marqueeBox.map((item, index) => (
            <div key={index} className="mr-24">
              <div className={`flex gap-2 ${styles.tickerText}`}>
                <div>
                  <p className="text-green-color">{item.colouredText}</p>
                </div>
                <div>
                  <p className="italic text-dull-color">{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default MarqueeComponent;
