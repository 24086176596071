import React, { useEffect, useRef } from "react";
import styles from "./Framework.module.css";
import Button from "../button/Button"

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const topdata = [
  {
    img: "/assets/images/logo.png",
    heading: "Replenishing Reserves: Direct Liquidity Injections",
    subContent:
      "MoonVault injects tokens directly into the liquidity pool, enhancing liquidity without additional steps or gas fees. This method avoids visible sell transactions, ensuring market stability and value retention. The burning of initial LP for $MVT naturally decentralizes these liquidity injections.",
  },
  {
    img: "/assets/images/logo.png",
    heading: "Direct Dividends: Cosmic Rewards",
    subContent:
      "MoonVault distributes dual dividends directly to users as $WETH and $MVT, supporting market price of $MVT through buybacks. This feature ensures that holding $MBT tokens is continuously beneficial, promoting long-term engagement and value growth.",
  },
];
const bottomdata = [
  {
    img: "/assets/images/logo.png",
    heading: "Refuel and Rebalance: Strategic Token Supply Reduction",
    subContent:
      "Tokens are burned to permanently decrease the total supply, leading to an increase in token value as the market rebalances. This ensures each remaining token represents a larger proportion of the liquidity pool's value.",
  },
  {
    img: "/assets/images/logo.png",
    heading: "Incentivizing Participation: Auto-Compounding Rewards for Liquidity Providers",
    subContent:
      "Liquidity providers benefit from LP injections via direct rewards as LP holders, furthered by the natural auto-compounding effect of the automated $MVT tokenomics. This encourages long-term participation and value growth.",
  },
];
const Framework = () => {
  const frameworkRef = useRef([]);

  useEffect(() => {
    frameworkRef.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          x: "10%",
          // markers:true
        },
        {
          duration: 0.5,
          autoAlpha: 1,
          x: 0,
          ease: "power2.out",
          scrollTrigger: {
            trigger: el,
            start: "top bottom-=20%",
            toggleActions: "play none none reverse",
            // markers:true,
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !frameworkRef.current.includes(el)) {
      frameworkRef.current.push(el);
    }
  };
  return (
    // Framework Section Starts
    <section id="FRAMEWORK" className="flex justify-center w-full pt-5 pb-20 FrameworkParent">
      {/*Framework Child Section Starts */}
      <div className="FrameworkChild  w-[95%] sm:w-[85%]  text-white">
        <div className="py-20 context">
          {/* TagLine */}
          <h4 className={styles.tagline}>/ FRAMEWORK</h4>
          {/* Heading */}
          <h3 className={`w-full lg:w-[59%] ${styles.heading}`}>
            Unveiling the Revolving MoonVault Framework
          </h3>
          {/* Sub-Content */}
          <p className={styles.subcontent}>
            Discover the Power of the Revolving S.T.A.R.R. Engine within MoonVault:
          </p>
          <div className="flex pl-2 py-7">
            <div>
              <Button label={"MEDIUM"} link={"medium.com/@moonvaulteth"} />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-9">
          <div className="flex flex-wrap gap-4 topclass">
            {topdata.map((items, index) => {
              return (
                <div ref={addToRefs}
                  className={`flex ${styles.card} items-stretch w-full h-auto sm:h-[350px] ${index % 2 === 0 ? "lg:w-[50%]" : "lg:w-[45%]"
                    }`}
                  key={index}
                >
                  <div className="flex justify-between">
                    <h2 className={styles.tagline}>{items.heading}</h2>
                    <div className="image w-[30%] sm:w-[11%] xl:w-[15%] flex justify-end">
                      <img src={items.img} className="max-w-[45px] lg:max-w-[55px]" alt="globe" />
                    </div>
                  </div>

                  <p className={styles.subcontent}>{items.subContent}</p>
                </div>
              );
            })}
          </div>
          <div className="flex flex-wrap justify-end gap-4 bottomclass">
            {bottomdata.map((items, index) => {
              return (
                <div ref={addToRefs}
                  className={`flex ${styles.card} w-full h-auto sm:h-[350px] ${index % 2 === 0 ? "lg:w-[50%]" : "lg:w-[45%]"
                    }`}
                  key={index}
                >
                  <div className="flex justify-between">
                    <h2 className={styles.tagline}>{items.heading}</h2>
                    <div className="image w-[30%] sm:w-[11%] xl:w-[15%] flex justify-end">
                      <img src={items.img} className="max-w-[45px] lg:max-w-[55px]" alt="globe" />
                    </div>
                  </div>

                  <p className={styles.subcontent}>{items.subContent}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*Framework Child Section Ends */}
    </section>
    // Framework Section Ends
  );
};

export default Framework;
